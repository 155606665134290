var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "flash-messages" },
    [
      _c(
        "TransitionGroup",
        { attrs: { mode: "out-in", name: "slide" } },
        _vm._l(_vm.alerts, function(alert) {
          return _c("Alert", {
            key: alert.id,
            attrs: { id: alert.id, type: alert.type, message: alert.message },
            on: { remove: _vm.removeAlert }
          })
        }),
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }